<template>
  <div class="column is-4 is-offset-4">
    <h3 class="title has-text-black">Signup</h3>
    <hr class="login-hr">
    <p class="subtitle has-text-black">Please signup to proceed.</p>
    <div class="box">
      <figure class="avatar">
        <img src="@/assets/logo.png"/>
      </figure>
      <form v-on:submit.prevent="validateBeforeSubmit">
        <div class="field">
          <div class="control">
            <input class="input is-large" type="email" placeholder="Your Email" autofocus="" v-model="model.email">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input class="input is-large" type="password" placeholder="Your Password" v-model="model.password">
          </div>
        </div>
        <button class="button is-block is-primary is-large is-fullwidth">Signup</button>
      </form>
    </div>
    <p class="has-text-grey">
      <router-link :to="{name: 'login'}">
        Login
      </router-link>
    </p>
  </div>
</template>

<script>

import { EventBus } from '@/utils'
// import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'Signup',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      model: {
        email: '',
        username: '',
        mobile_phone: '',
        password: ''
      },
      next: {
        name: 'login'
      }
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  beforeDestroy: function(){
    EventBus.$off('successRegistering')
    EventBus.$off('errorRegistering')
    EventBus.$off('failedRegistering')
  },
  mounted: function(){
    EventBus.$on('successRegistering', () => {
      this.$router.push(this.next)
    })

    EventBus.$on('errorRegistering', () => {
      this.model.password = ''
    })

    EventBus.$on('failedRegistering', () => {
      this.model.password = ''
    })
  },
  methods: {
    validateBeforeSubmit() {
      let $this = this;
      $this.sendPayload($this.model)
    },
    sendPayload: function (payload) {
      let $this = this;

      $this.$store.dispatch('user.register', payload).then(() => {
        // $this.$router.push($this.next)
      })
    }
  }
}
</script>

<style scoped>
/*html,body {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  font-weight: 300;
}*/
.hero.is-success {
  background: #F2F6FA;
}
.hero .nav, .hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr{
  border-bottom: 1px solid black;
}

.has-text-black{
  color: black;
}

.field{
  padding-bottom: 10px;
}

.fa{
  margin-left: 5px; 
}
</style>
<style>
</style>